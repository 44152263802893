<template>
  <div>
    <div class="container-fluid pt-4">
      <div class="row">
        <div class="col-12">
          <h1>
            {{ pageTitle }}
          </h1>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
let utils = require("@/assets/global/js/utils.js");

export default {
  data: function () {
    return {
      pageTitle: "Manage Workspaces"
    };
  },
  mounted: function () {
    //
  },
  methods: {
    //
  }
};
</script>

<style lang="scss">
@import "./SettingsWorkspace.scss";
</style>
